/* eslint-disable @typescript-eslint/no-base-to-string */
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';

import { css } from '@emotion/react';
import { Footer } from '../../components/Footer';
import SiteNav from '../../components/header/SiteNav';
import { PostFullContent } from '../../components/PostContent';
import { Wrapper } from '../../components/Wrapper';
import IndexLayout from '../../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../../styles/shared';
import {
  NoImage,
  PostFull,
  PostFullHeader,
  PostFullTitle,
} from '../../templates/post';
import { colors } from '../../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

export interface SetupItem {
  emoji: string;
  name: string;
  link: string;
  category: 'furniture' | 'screens' | 'devices' | 'gadgets';
}

export const setupItems: SetupItem[] = [
  // Furniture
  {
    emoji: '⎍',
    name: 'Ikea IDÄSEN',
    link: 'https://www.ikea.com/de/de/p/idasen-schreibtisch-sitz-steh-braun-dunkelgrau-s39281004/',
    category: 'furniture',
  },
  {
    emoji: '💺',
    name: 'Ikea STYRSPEL',
    link: 'https://www.ikea.com/nl/en/p/styrspel-gaming-chair-dark-grey-grey-20522032/#content',
    category: 'furniture',
  },

  // Screens
  {
    emoji: '🖥️',
    name: 'Dell P2417H',
    link: 'https://www.amazon.de/Dell-Monitor-Display-Reaktionszeit-General%C3%BCberholt/dp/B07QGQ2JZ6/',
    category: 'screens',
  },
  {
    emoji: '🖥️',
    name: 'SAMSUNG S60UA',
    link: 'https://www.amazon.com/SAMSUNG-Adjustable-TUV-Certified-Intelligent-LS27A600UUNXGO/dp/B08YGQBB69',
    category: 'screens',
  },

  // Devices
  {
    emoji: '📱',
    name: 'Pixel 9 Pro',
    link: 'https://www.samsung.com/us/smartphones/galaxy-s22/',
    category: 'devices',
  },
  {
    emoji: '📱',
    name: 'iPad 9',
    link: 'https://www.apple.com/uk/ipad-10.2/',
    category: 'devices',
  },
  {
    emoji: '💻',
    name: '14-inch Apple M4 Pro 48gb',
    link: 'https://www.apple.com/uk/shop/buy-mac/macbook-pro/',
    category: 'devices',
  },
  {
    emoji: '💻',
    name: '16-inch Apple M1 Pro 32gb',
    link: 'https://www.apple.com/uk/macbook-pro-14-and-16/',
    category: 'devices',
  },

  // Gadgets
  {
    emoji: '🎧',
    name: 'Sony WH-1000xm4',
    link: 'https://www.sony.de/electronics/kopfband-kopfhoerer/wh-1000xm4',
    category: 'gadgets',
  },
  {
    emoji: '⌨️',
    name: 'Dygma Raise 2',
    link: 'https://dygma.com/pages/dygma-raise-2',
    category: 'gadgets',
  },
  {
    emoji: '🖱️',
    name: 'Logi MX Anywhere 2s',
    link: 'https://www.amazon.de/-/en/Exclusive-Bluetooth-connection-Rechargeable-multi-device/dp/B0761YPDNM',
    category: 'gadgets',
  },
];

export const SetupList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 2rem 0;

  li {
    margin: 1rem 0;
    padding: 0.75rem;
    border-radius: 8px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 1rem;

    &:hover {
      transform: translateX(4px);
    }
  }

  .emoji {
    font-size: 1.5rem;
    min-width: 2rem;
  }

  a {
    color: ${colors.blue};
    text-decoration: none;
    border-bottom: none;
    box-shadow: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

interface SetupSectionProps {
  title: string;
  items: SetupItem[];
}

function SetupSection({ title, items }: SetupSectionProps): JSX.Element {
  return (
    <>
      <h2>{title}</h2>
      <SetupList>
        {items.map(item => (
          <li key={`${item.category}-${item.name}`}>
            <span className="emoji">{item.emoji}</span>
            <a href={item.link}>{item.name}</a>
          </li>
        ))}
      </SetupList>
    </>
  );
}

const containerStyle = css`
  .site-main {
    margin-right: 24px;
    margin-left: 24px;
  }
`;

function DeskSetup() {
  const furnitureItems = setupItems.filter(
    item => item.category === 'furniture',
  );
  const screenItems = setupItems.filter(item => item.category === 'screens');
  const deviceItems = setupItems.filter(item => item.category === 'devices');
  const gadgetItems = setupItems.filter(item => item.category === 'gadgets');

  return (
    <IndexLayout css={containerStyle}>
      <Helmet>
        <title>Desk Setup</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header
          className="site-archive-header no-image"
          css={[SiteHeader, SiteArchiveHeader]}
        >
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false} />
            </div>
          </div>
        </header>
        <main id="site-main" className={`site-main ${SiteMain} ${outer}`}>
          <div css={inner}>
            <article className={`${PostFull} post page ${NoImage}`}>
              <PostFullHeader>
                <PostFullTitle>Desk Setup</PostFullTitle>
              </PostFullHeader>

              <PostFullContent className="post-full-content">
                <div className="text-container">
                  <p>
                    So, this is an overview of my current work-from-home setup
                    which I use mostly use for coding, writing and studying.
                    Some of this stuff I really like, some of them just gets the
                    job done, but in general I am pretty happy with it.
                  </p>
                </div>
                <div className="post-content">
                  <SetupSection title="Furniture" items={furnitureItems} />
                  <SetupSection title="Screens" items={screenItems} />
                  <SetupSection title="Devices" items={deviceItems} />
                  <SetupSection title="Gadgets" items={gadgetItems} />
                </div>
              </PostFullContent>
            </article>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
}

export default DeskSetup;
